import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "/src/components/Layout";
import * as styles from "./practitioner.module.css";
import BookNow from "/src/components/BookNow";
import { SEO } from "/src/components/SEO";

const PractitionerPage = (props) => {
  const {
    data: { practitioner },
  } = props;

  return (
    <Layout>
      <div className={styles.container}>
        <h1>
          {practitioner.prefix ? practitioner.prefix + " " : null}
          {practitioner.firstName ? practitioner.firstName + " " : null}
          {practitioner.lastName ? practitioner.lastName : null}
          {practitioner.suffix ? ", " + practitioner.suffix : null}
        </h1>
        <p>
          {practitioner.photo?.url ? (
            <img
              className={styles.portrait}
              src={practitioner.photo.url}
              alt=""
            />
          ) : null}
          <div dangerouslySetInnerHTML={{ __html: practitioner.bio.html }} />
        </p>
        <div className={styles.disciplineCtr}>
          <h2>
            {practitioner.prefix ? practitioner.prefix + " " : null}
            {practitioner.firstName}'s Disciplines
          </h2>
          <ul>
            {practitioner.services
              .sort((a, b) => a.orderPosition - b.orderPosition)
              .map((item, index) => (
                <li key={index}>
                  <Link to={`/disciplines/${item.urlSlug}`}>{item.name}</Link>
                </li>
              ))}
          </ul>
        </div>
        <div style={{ width: "fit-content" }}>
          <BookNow
            label={`Book Appointment with ${
              practitioner.prefix ? practitioner.prefix + " " : ""
            }${practitioner.firstName}`}
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    practitioner: graphCmsPractitioner(urlSlug: { eq: $slug }) {
      prefix
      firstName
      lastName
      suffix
      orderPosition
      urlSlug
      photo {
        url
      }
      bio {
        html
      }
      services {
        name
        urlSlug
        orderPosition
      }
    }
  }
`;

export default PractitionerPage;

export const Head = () => <SEO />;
